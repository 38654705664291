import React from "react";
import CharitiesView from "../components/CharitiesView";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";
import { getStyles } from "../store/selectors";
import { donateGift, locateMeIfNeeded } from "../store/user/actions";

const CharitiesPage = (props) => <CharitiesView {...props} />;

const mapStateToProps = (state, props) => {
  const { user, app } = state;
  return {
    user,
    app,
    appStyles: getStyles(state, props),
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    donateGift: (charityId) => dispatch(donateGift(charityId)),
    locateMeIfNeeded: (google) => dispatch(locateMeIfNeeded(google)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(CharitiesPage));
