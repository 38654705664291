import _ from "lodash";
import React from "react";
import { navigateTo } from "../../utils/location";
import { mediaTypes } from "../../utils/media";
import AppContainer from "../AppContainer";
import Button from "../Button";
import Card from "../Card";
import ExternalLink from "../ExternalLink";
import GiftIcon from "../icons/Gift.svg";
import Loader from "../Loader";
import * as styles from "./index.module.scss";

export default class extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.user.donateGiftState.sent &&
      this.props.user.donateGiftState.sent
    ) {
      this.nextPageTransition = setTimeout(() => navigateTo("/donate"), 300);
    }

    if (!prevProps.app.google && this.props.app.google) {
      this.props.locateMeIfNeeded(this.props.app.google);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.nextPageTransition);
  }

  donateGift = (charityId) => () => {
    this.props.donateGift(charityId);
  };

  render() {
    const {
      T,
      user,
      pageContext: { charities },
      appStyles,
      app,
    } = this.props;

    const { PageHeader = {} } = appStyles;
    console.log({ charities });
    if (
      app.isSSR ||
      (!_.get(user, "userLocatedAddress.geoPoint") &&
        user.autoGeoLocation &&
        !_.get(user, "tempLocation.error") &&
        _.get(user, "tempLocation.loading"))
    ) {
      return (
        <AppContainer.Content appStyles={appStyles} tightBottom>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Locating Local Charities...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    const userDistanceFrom = _.get(user, "userLocatedAddress.geoPoint")
      ? (charity) => {
          // not requiring google accurate distance calculation
          const { longitude, latitude } = charity;
          return (
            (longitude - user.userLocatedAddress.geoPoint.longitude) ** 2 +
            (latitude - user.userLocatedAddress.geoPoint.latitude) ** 2
          );
        }
      : _.identity;

    return (
      <AppContainer.Content appStyles={appStyles}>
        <div
          style={{ ...PageHeader, textAlign: "center", marginBottom: "0px" }}
        >
          <h3>{T("Local Charities")}</h3>
        </div>
        <div className={styles.CharityViewDescription}>
          {T(
            "You can donate the rewards we send to any of the charities below",
          )}
        </div>
        <div className={styles.Charities}>
          {_.map(_.sortBy(charities, userDistanceFrom), (charity) => {
            return (
              <Card
                key={charity.id}
                appStyles={appStyles}
                style={{ overflow: "hidden" }}
                classNames={styles.Card}
              >
                <Card.Image
                  sizes="600px"
                  mediaKey={charity.imageKey}
                  mediaType={mediaTypes.charity}
                  imagePreview={charity.imagePreview}
                  noImageRenderer={GiftIcon}
                  imgStyle={{ objectFit: "contain" }}
                  wrapperStyle={{ height: "150px", width: "100%" }}
                />
                <Card.Content>
                  <span className={styles.CharityCardDescription}>
                    <Card.Title
                      style={{ margin: "0px", display: "inline-block" }}
                      appStyles={appStyles}
                    >
                      {T(charity.name)}
                    </Card.Title>
                    <Card.Description style={{ margin: "6px 10px 0 0" }}>
                      {charity.websiteUrl && (
                        <ExternalLink
                          link={charity.websiteUrl}
                          style={{
                            color: appStyles.actionColor,
                            textDecoration: "underline",
                          }}
                          fallbackLink={this.props.location}
                          appStyles={appStyles}
                        >
                          {T("Learn More")}
                        </ExternalLink>
                      )}
                    </Card.Description>
                  </span>
                  <span className={styles.CharityCardAction}>
                    <Button
                      appStyles={appStyles}
                      centered
                      marginTop
                      onClick={this.donateGift(charity.id)}
                      loading={
                        _.isEqual(
                          charity.id,
                          user.donateGiftState.selectedCharityId,
                        ) && user.donateGiftState.sending
                      }
                      completed={
                        _.isEqual(
                          charity.id,
                          user.donateGiftState.selectedCharityId,
                        ) && user.donateGiftState.sent
                      }
                    >
                      {T("Donate")}
                    </Button>
                  </span>
                </Card.Content>
              </Card>
            );
          })}
        </div>
      </AppContainer.Content>
    );
  }
}
